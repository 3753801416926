<script setup>
const emit = defineEmits(['next'])
defineProps({
  errorMessage: {
    type: String,
    default: '',
  },
})

const localErrorMessage = ref('')
const form = defineModel({ type: Object, required: true })
const validPhoneNumber = ref(false)

function nextStep() {
  if (form.value.password1 !== form.value.password2) {
    localErrorMessage.value = 'Wachtwoorden komen niet overeen.'
  } else if (!validPhoneNumber.value) {
    localErrorMessage.value = 'Telefoonnummer is niet geldig.'
  } else {
    emit('next')
  }
}

function handleUpdateResult(object) {
  validPhoneNumber.value = object.isValid
  form.value.phone = object.e164
}
</script>

<template>
  <RegisterStep
    title="Algemene gegevens"
    :error-message="errorMessage || localErrorMessage"
    :step="1"
    @next="nextStep"
  >
    <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 items-start">
      <RegisterInputLabel title="Voornaam">
        <Input v-model="form.firstName" required />
      </RegisterInputLabel>

      <RegisterInputLabel title="Achternaam">
        <Input v-model="form.lastName" required />
      </RegisterInputLabel>

      <RegisterInputLabel title="Telefoonnummer">
        <PhoneNumber v-model="form.phone" @update-result="handleUpdateResult" />
        <p class="text-sm flex text-gray-500">
          We gebruiken deze informatie om je identiteit te verifiëren.
        </p>
      </RegisterInputLabel>

      <RegisterInputLabel title="Email">
        <Input v-model="form.email" type="email" required />
      </RegisterInputLabel>

      <RegisterInputLabel title="Wachtwoord">
        <Input
          v-model="form.password1"
          type="password"
          required
          minlength="6"
        />
      </RegisterInputLabel>

      <RegisterInputLabel title="Bevestig wachtwoord">
        <Input
          v-model="form.password2"
          type="password"
          required
          minlength="6"
        />
      </RegisterInputLabel>
    </div>
  </RegisterStep>
</template>
