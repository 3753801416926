<script setup>
defineProps({
  errorMessage: {
    type: String,
    default: '',
  },
  submitDisabled: {
    type: Boolean,
    default: false,
  },
})

const termsLink = 'https://heyu.works/algemene-voorwaarden-professional'

const form = defineModel({ type: Object, required: true })
</script>

<template>
  <RegisterStep
    title="Contactgegevens"
    :step="3"
    next-step-title="Registratie afronden"
    :submit-disabled="submitDisabled"
    :error-message="errorMessage"
  >
    <div class="grid grid-cols-1 gap-6 sm:grid-cols-2">
      <RegisterInputLabel title="Adres" class="sm:col-span-2">
        <Input v-model="form.address" focus-on-mount required />
      </RegisterInputLabel>

      <RegisterInputLabel title="Postcode">
        <Input v-model="form.postalCode" required />
      </RegisterInputLabel>

      <RegisterInputLabel title="Woonplaats">
        <Input v-model="form.city" required />
      </RegisterInputLabel>

      <RegisterInputLabel title="Land">
        <div class="select-wrapper relative mb-2">
          <select
            v-model="form.country"
            class="mt-1 block w-full appearance-none rounded-lg bg-gray-50 py-3 pl-3 pr-10 text-base text-gray-900"
            required
          >
            <option value="" disabled selected>Kies een land</option>
            <option value="NL">Nederland</option>
            <option value="BE">Belgie</option>
            <option value="DE">Duitsland</option>
          </select>
          <div
            class="pointer-events-none absolute bottom-0 right-0 top-0 flex items-center pr-2"
          >
            <IconSelect class="h-3 w-3" />
          </div>
        </div>
      </RegisterInputLabel>

      <RegisterInputLabel title="Geboortedatum">
        <Input v-model="form.birthDate" type="date" required />
      </RegisterInputLabel>

      <div class="flex items-center gap-4 text-secondary sm:col-span-2">
        <Checkbox v-model="form.agreedToTerms" required />
        <span class="font-semibold"
          >Ik ga akkoord met de
          <a class="underline" :href="termsLink" target="_blank"
            >algemene voorwaarden</a
          ></span
        >
      </div>
    </div>
  </RegisterStep>
</template>
