export function useApiOtp() {
  const client = useStrapiClient()

  async function createOtp(receiver: string): Promise<{ id: number }> {
    try {
      const response = await client('/otps', {
        method: 'POST',
        body: { data: { receiver } },
      })

      if (!response) {
        throw new Error('Failed to create OTP')
      }

      return { id: response.id }
    } catch (error) {
      throw error
    }
  }

  async function validateOtp(
    otp: number,
    otpId: number,
  ): Promise<Response | unknown> {
    if (!otp || !otpId) throw new Error('OTP and OTP ID are required')
    try {
      const response: Response = await client('/otps/validate', {
        method: 'POST',
        body: {
          otp,
          otpId,
        },
      })

      return response
    } catch (error) {
      return error
    }
  }

  return {
    createOtp,
    validateOtp,
  }
}
