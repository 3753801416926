<script setup>
definePageMeta({
  middleware: ['not-authenticated'],
})

const route = useRoute()
const { register } = useStrapiAuth()
const { executeRecaptcha } = useNuxtApp()?.$recaptcha

const generalData = ref({
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  password1: '',
  password2: '',
})

const contactData = ref({
  address: '',
  postalCode: '',
  birthDate: '',
  city: '',
  country: '',
  agreedToTerms: false,
})

const saving = ref(false)
const errorMessage = ref('')
const step = ref(1)
const otpId = ref(null)

function nextStep() {
  errorMessage.value = ''

  if (step.value < 3) {
    step.value++
  } else if (step.value === 3) {
    submit()
  }
}

function previousStep() {
  if (step.value > 1) {
    step.value--
  }
}

async function submit() {
  saving.value = true

  const address = {
    address: contactData.value.address,
    zip_code: contactData.value.postalCode,
    city: contactData.value.city,
    country: contactData.value.country,
  }

  const form = {
    recaptchaToken: await executeRecaptcha('register'),
    referral: route.query?.referral,
    type: 'professional',
    contact: {
      first_name: generalData.value.firstName,
      last_name: generalData.value.lastName,
      phone_number: generalData.value.phone,
      date_of_birth: contactData.value.birthDate,
    },
    address,
    username: generalData.value.email,
    email: generalData.value.email,
    password: generalData.value.password1,
    passwordConfirm: generalData.value.password2,
    professionalDetails: {
      address,
    },
    preferences: {
      dontReceiveCronJobEmails: false,
    },
    otpId: otpId.value,
  }
  try {
    const res = await register(form)
    const userId = res?.user?.value?.id

    window?.dataLayer?.push({
      event: 'registerProfessional',
      email: generalData.value.email,
      lname: generalData.value.lastName,
      fname: generalData.value.firstName,
    })

    navigateTo('/thankyou')
  } catch (e) {
    saving.value = false
    switch (e.error.message) {
      case 'Email or Username are already taken':
        errorMessage.value = 'Dit e-mailadres is al in gebruik.'
        step.value = 1
        break
      case 'email must be a valid email':
        errorMessage.value = 'Dit e-mailadres is niet geldig.'
        step.value = 1
        break
      case 'password must be at least 6 characters':
        errorMessage.value = 'Het wachtwoord moet minimaal 6 tekens bevatten.'
        step.value = 1
        break
      default:
        errorMessage.value =
          'Er ging iets mis bij het registreren. Check uw gegevens en probeer het opnieuw.'
    }
  }
}

function handleOtpId(id) {
  otpId.value = id
}
</script>

<template>
  <div class="flex flex-col items-center">
    <RegisterHero title="Registreren als professional" />
    <div class="flex w-full justify-center">
      <div class="max-w-3xl grow px-6">
        <RegisterProfessionalGeneralData
          v-if="step === 1"
          v-model="generalData"
          :error-message="errorMessage"
          class="my-12 bg-white"
          @next="nextStep"
        />
        <RegisterProfessionalOtpData
          v-if="step === 2"
          :error-message="errorMessage"
          class="my-12 bg-white"
          :phone="generalData.phone"
          @next="nextStep"
          @prev="previousStep"
          @otp-id="handleOtpId"
        />
        <RegisterProfessionalContactData
          v-if="step === 3"
          v-model="contactData"
          :error-message="errorMessage"
          :submit-disabled="saving"
          class="my-12 bg-white"
          @next="nextStep"
          @prev="previousStep"
        />
      </div>
    </div>
  </div>
</template>
