<script setup>
import { useApiOtp } from '~/composables/api/useApiOtp'

const emit = defineEmits(['next', 'prev', 'otp-id'])
const props = defineProps({
  errorMessage: {
    type: String,
    default: '',
  },
  phone: {
    type: String,
    required: true,
  },
})
const { createOtp, validateOtp } = useApiOtp()

const localErrorMessage = ref('')
const countDown = ref(30)
const otpId = ref(null)
const otp = ref(null)
const intervalId = null

const startCountdown = () => {
  const intervalId = setInterval(() => {
    if (countDown.value > 0) {
      countDown.value -= 1
    } else {
      clearInterval(intervalId)
    }
  }, 1000)
}

onUnmounted(() => {
  clearInterval(intervalId)
})

onMounted(async () => {
  try {
    const result = await createOtp(props.phone)

    otpId.value = result.id
    emit('otp-id', otpId.value)
    startCountdown()
  } catch (error) {
    localErrorMessage.value = 'Er is iets misgegaan, probeer het later opnieuw.'
  }
})

async function nextStep() {
  const response = await validateOtp(Number(otp.value), otpId.value)
  if (response.error) {
    localErrorMessage.value = 'Er is iets misgegaan, probeer het later opnieuw.'
  } else {
    emit('next')
  }
}

async function regenerateOTP() {
  try {
    const result = await createOtp(props.phone)

    otpId.value = result.id
    countDown.value = 30
    startCountdown()
  } catch (error) {
    localErrorMessage.value = 'Er is iets misgegaan, probeer het later opnieuw.'
  }
}
</script>

<template>
  <RegisterStep
    title="Verifieer jouw telefoonnummer"
    :error-message="errorMessage || localErrorMessage"
    :step="2"
    next-step-title="Verifieer telefoonnummer"
    @next="nextStep"
    @prev="emit('prev')"
  >
    <div class="flex sm:items-end gap-4 flex-col sm:flex-row">
      <RegisterInputLabel title="Verificatiecode">
        <Input
          v-model="otp"
          type="text"
          autocomplete="one-time-code"
          inputmode="numeric"
          maxlength="6"
          pattern="\d{6}"
        />
      </RegisterInputLabel>

      <Button
        theme="secondary"
        :disabled="countDown !== 0"
        size="md"
        :class="'h-11 w-full sm:w-fit'"
        has-icon-right
        type="button"
        @click="regenerateOTP"
      >
        <template #icon-right>
          <div class="flex gap-x-2 items-center">
            <IconRefresh class="text-white h-4" />
            <p>{{ countDown !== 0 ? `${countDown}s` : '' }}</p>
          </div>
        </template>
      </Button>
    </div>
  </RegisterStep>
</template>
